import Request from './Api';

// get product(s)
const get = async (props) => {
    return (
        Request({
            url: "/product",
            data: {...props, "max_records": props.max_records || 999},
            method: "POST",
        })
    );
}

const getSingle = async (props) =>{
    return (
        Request({
            url: `/product/product/${props.id}`,
            data: props,
            method: "GET"
        })
    )
}

const getFiltered = async (props) => {
    return (
        Request({
            url: "/product",
            data: props,
            method: "POST"
        })
    );
}

const getSubscriptions = async (props) => {
    return (
        Request({
            url: "/product",
            data: {...props, "product_type_id": 1},
            method: "POST",
        })
    );
}

const addImage = async(props)=>{
    return(
        Request({
            url: "/product/upload",
            data: props,
            method: "POST"
        })
    )
}

// create product
const create = async (props) => {

    // sets mock data for testing - will be removed when api is ready
    //let mockdata = {data:null,errors:["Product Name is required", "Category is required."]};
    //let mockdata = {data:1,errors:null};
    
    return (
        Request({
            url: "/product/add",
            data: props,
            method: "POST"
            //test: mockdata // send mock data to simulate an api call
        })
    );
}

// update product
const update = async (props) => {

    // sets mock data for testing - will be removed when api is ready
    //let mockdata = {data:null,errors:["Product Name is required", "Category is required."]};
    //let mockdata = {data:1,errors:null};
    
    return (
        Request({
            url: "/product/edit",
            data: props,
            method: "POST"
            //test: mockdata // send mock data to simulate an api call
        })
    );
}

// update products with bulk-edit
const updateBulk = async (props) => {
    return (
        Request({
            url: "/product/bulk-edit",
            data: props,
            method: "POST"
        })
    );
}


// Categories
const Categories = {

    get: async(props)=>{
        return (
            Request({
                url: "/category/" + (props?.id || ""),
                data: props,
            })
        );
    },

    // create categories
    create: async(props)=>{
        return (
            Request({
                url: "/category/add",
                data: props,
                method: "POST"
            })
        );
    },

    // update categories
    update: async(props)=>{
        return (
            Request({
                url: "/category/edit",
                data: props,
                method: "POST"
            })
        );
    },

    filter: async(props)=>{
        return(
            Request({
                url: "/category",
                data: props,
                method: "POST"
            })
        )
    }
}


// Types
const Types = {

    // ge types
    get: async(props)=>{
        return (
            Request({
                url: "/product/type/" + (props?.id || ""),
                data: props
                //test: mockdata // send mock data to simulate an api call
            })
        );
    },

    /*// create types
    create: async(props)=>{
        // sets mock data for testing - will be removed when api is ready
        //let mockdata = {data:null,errors:["Product Type Name is required"]};
        //let mockdata = {data:1,errors:null};
        
        return (
            Request({
                url: "/product/type/create",
                data: props,
                //test: mockdata // send mock data to simulate an api call
            })
        );
    },

    // update types
    update: async(props)=>{
        // sets mock data for testing - will be removed when api is ready
        //let mockdata = {data:null,errors:["Product Type Name is required"]};
        let mockdata = {data:1,errors:null};
        
        return (
            Request({
                url: "/product/type/update",
                data: props,
                test: mockdata // send mock data to simulate an api call
            })
        );
    },*/
}


// Status
const Status = {

    // ge status
    get: async(props)=>{
        // sets mock data for testing - will be removed when api is ready
        //let mockdata = {data:null,errors:["Status Name is required"]};
        let mockdata = {
            data:[
                {
                    id: 1,
                    name: "Active",
                },
                {
                    id: 2,
                    name: "Pending",
                },
                {
                    id: 3,
                    name: "Not Available",
                },
                {
                    id: 4,
                    name: "Removed",
                },
            ],
            errors:null
        };

        if (props?.id){
            const filtered_data=mockdata.data.filter(item => item.id===parseInt(props.id));
            mockdata.data=filtered_data;
        }
        
        return (
            Request({
                url: "/product/status",
                data: props,
                test: mockdata // send mock data to simulate an api call
            })
        );
    },

}

const Variants = {
    get: async (props) => {
        return (
            Request({
                url: "/product/variant/" + (!props ? "" : props.id),
                data: props,
                method: "GET",
            })
        )
    },

    create: async (props) => {
        return (
            Request({
                url: "/product/variant/add",
                data: props,
                method: "POST"
            })
        )
    },

    edit: async (props) => {
        return (
            Request({
                url: "/product/variant/edit",
                data: props,
                method: "POST"
            })
        )
    },

    delete: async (props) => {
        return (
            Request({
                url: "/product/variant/delete",
                data: props,
                method: "DELETE"
            })
        )
    },

    Addons: {
        get: async (props) => {
            const mockdata={
                "errors" : [],
                "data" : [
                  {
                    "category_id":55,
                    "category_name":"Meats",
                    "add_ons":[
                      {
                          "id": 22,
                          "product_id": 2,
                          "name": "Pepperoni",
                          "price": 0.99,
                          "sale_price": 0.98,
                          "token_price": 0,
                          "bill_interval": 0,
                          "interval_quantity": 0,
                          "activation_fee": 0,
                          "sku": "CDY-GMB-05",
                          "upc": "294058202513",
                          "is_taxable": 1,
                          "is_shippable": 0,
                          "handling_fee": 0,
                          "fulfillment_fee": 0,
                          "weight": null,
                          "height": null,
                          "width": null,
                          "length": null,
                          "expires_in_days": 0,
                          "date_available": "",
                          "quantity_units": 1,
                          "minimum_qty": 1,
                      },
                      {
                          "id": 56,
                          "product_id": 2,
                          "name": "Ham",
                          "price": 0.99,
                          "sale_price": 0.98,
                          "token_price": 0,
                          "bill_interval": 0,
                          "interval_quantity": 0,
                          "activation_fee": 0,
                          "sku": "CDY-GMB-05",
                          "upc": "294058202513",
                          "is_taxable": 1,
                          "is_shippable": 0,
                          "handling_fee": 0,
                          "fulfillment_fee": 0,
                          "weight": null,
                          "height": null,
                          "width": null,
                          "length": null,
                          "expires_in_days": 0,
                          "date_available": "",
                          "quantity_units": 1,
                          "minimum_qty": 1,
                      },
                    ]
                  },
                  {
                    "category_id":56,
                    "category_name":"Veggies",
                    "add_ons":[
                      {
                          "id": 22,
                          "product_id": 2,
                          "name": "Mushrooms",
                          "price": 0.99,
                          "sale_price": 0.98,
                          "token_price": 0,
                          "bill_interval": 0,
                          "interval_quantity": 0,
                          "activation_fee": 0,
                          "sku": "CDY-GMB-05",
                          "upc": "294058202513",
                          "is_taxable": 1,
                          "is_shippable": 0,
                          "handling_fee": 0,
                          "fulfillment_fee": 0,
                          "weight": null,
                          "height": null,
                          "width": null,
                          "length": null,
                          "expires_in_days": 0,
                          "date_available": "",
                          "quantity_units": 1,
                          "minimum_qty": 1,
                      },
                      {
                          "id": 125,
                          "product_id": 2,
                          "name": "Onions",
                          "price": 0.99,
                          "sale_price": 0.98,
                          "token_price": 0,
                          "bill_interval": 0,
                          "interval_quantity": 0,
                          "activation_fee": 0,
                          "sku": "CDY-GMB-05",
                          "upc": "294058202513",
                          "is_taxable": 1,
                          "is_shippable": 0,
                          "handling_fee": 0,
                          "fulfillment_fee": 0,
                          "weight": null,
                          "height": null,
                          "width": null,
                          "length": null,
                          "expires_in_days": 0,
                          "date_available": "",
                          "quantity_units": 1,
                          "minimum_qty": 1,
                      },
                    ]
                  }      
                ]
              };            
            return (
                Request({
                    url: "/product/variant/" +props.id+"/addons",
                    data: props,
                    method: "GET"
                })
            )
        },
    },

    byProduct: async (props) => {
        return (
            Request({
                url: "/product/"+props.id+"/variants",
                data: props,
                method: "GET"
            })
        )
    },    
}

const setProductCategory = async (props) => {    
    return (
        Request({
            url: "/product/category/add",
            data: props,
            method: "POST"
        })
    );
}

const removeProductCategory = async (props) => {
    return (
        Request({
            url: "/product/category/delete",
            data: props,
            method: "DELETE"
        })
    );
}


const Products = {
    get, getSingle, getFiltered, getSubscriptions, addImage, create, update, updateBulk, setProductCategory, removeProductCategory, Categories, Types, Status, Variants //, delete, etc. ...
}
  
export default Products;
