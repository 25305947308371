import React,{useState,useEffect, useCallback} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';

import Products from '../../../../api/Products';
import * as actions from '../../../../store/actions';

import usePrevious from '../../../../components/common/CustomHooks'
import "./Categories.scss";

export const Categories = ({firstCat=true, ...props}) => {
    const dispatch = useDispatch();

    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [defaultCategories, setDefaultCategories] = useState([]);
    const [activeCategory,setActiveCategory] = useState(useSelector(state => state.pos[props.register_id].category));
    const oldActiveCategory = usePrevious(activeCategory);


    const categoryClickHandler = useCallback(async(id, subcategory=false, defaults=null, newCats=null) =>{
        let localCats
        if(newCats) localCats = newCats; //this should only be on initial load
        else localCats = categories;
        if (!id){ //all categories click (when there are extra categories).  
            dispatch(actions.activeCategory(0, props.register_id));
            if(defaults)setCategories(defaults); // should only be on initial load
            else setCategories(defaultCategories);
            setSubCategories([]);
        } else if (id !==oldActiveCategory && !subcategory){ //if no category is active or changing categories and we haven't clicked on a subcategory
            dispatch(actions.activeCategory(id, props.register_id));
            setActiveCategory(id);
            let subcats=[];
            if (localCats?.filter(a=>a.id===id)?.[0]?.parent) subcats=[];
            const response = await Products.Categories.get({id});
            if (response.data?.[0]?.children){
                subcats.push(...response.data[0].children.filter(child=>child.add_on_only===0))
            }
            setSubCategories(subcats);
        }else if (id !==oldActiveCategory && subcategory){ //if it's a subcategory and we just need to load it
            dispatch(actions.activeCategory(id, props.register_id))
            setActiveCategory(id);
        }else if(id === oldActiveCategory && !subcategory){ //closes the sub categories if they're open
            setSubCategories([])
            setActiveCategory()
            dispatch(actions.activeCategory(0, props.register_id));
        }
    },[defaultCategories,categories, dispatch, oldActiveCategory, props.register_id])

	useEffect(() => {
        const _getCategories = async () => {
            if (props.category_id){
                let categories = props.category_id;
                if (!Array.isArray(props.category_id)) categories=[props.category_id];
                let newCategories = []
                for(let each in categories){
                    const res = await Products.Categories.get({id:categories[each]});
                    if (res.data?.[0]?.children){
                        res.data[0].children.forEach((_, i) => {
                            res.data[0].children[i].parent = true;
                        });
                    }
                    newCategories.push(res.data[0])
                }
                setDefaultCategories(newCategories);
                setCategories(newCategories);
                categoryClickHandler(newCategories[0]?.id, false, newCategories, newCategories) //make the first category the one that loads subcategories
            }
        }

        if (props.category_id) _getCategories();
        //eslint-disable-next-line 
	}, [props.category_id]);

    useEffect(()=>{
        return ()=>{
            setCategories([]);
            setSubCategories([]);
            setDefaultCategories([]);
        }
    },[]);

    return (
        <>
            <div className="category-buttons">
                {categories && categories?.length === 1 && categories?.map((item,i)=> (
                    <Button as="a" size="sm" variant={+activeCategory===+item.id?"primary":"secondary"} data-cy="btn-category" className="btn-category" key={item.id+"-"+i} onClick={()=>categoryClickHandler(item.id)}>
                        All Categories
                    </Button>    
                ))}
                {categories && categories?.length > 1 && firstCat &&
                    <Button as="a" size="sm" variant={(!activeCategory || activeCategory===-1)?"primary":"secondary"} data-cy="btn-category-all" className="btn-category" onClick={()=>categoryClickHandler(0)}>
                        All Categories
                    </Button>
                }
                {categories && categories?.length > 1 && categories?.map((item,i)=> (
                    <Button as="a" size="sm" variant={+activeCategory===+item.id?"primary":"secondary"} data-cy="btn-category" className="btn-category" key={item.id+"-"+i} onClick={()=>categoryClickHandler(item.id)}>
                        {item.name}
                    </Button>
                ))}
            </div>
            {subCategories?.length>0 &&
                <div className="category-buttons subcategory">
                    <br />
                    <div className="subcategory-marker"><i className="far fa-angle-right"></i></div>
                    {subCategories?.map((item,i)=> (
                        <Button as="a" size="sm" variant={+activeCategory===+item.id?"primary":"secondary"} data-cy="btn-category-sub" className="btn-category" key={item.id+"-"+i} onClick={()=>categoryClickHandler(item.id, true)}>
                            {item.name}
                        </Button>
                    ))}
                </div>
            }
        </>
    );
}