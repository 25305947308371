import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch} from 'react-redux';
//import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import Pos from '../../api/Pos';
import Users from '../../api/Users';
import { convertRegisterDefinition, determineRegisterAvailability } from '../../utils/pos';
import { getRegisterInfo, loadOrderIntoRedux, updateCart, saveLogging } from '../../utils/thunks';
import ClosedHours from './ClosedHours';

//import { PosHeader } from './PosHeader.js'; // don't remove this, it is used while processing register definitions
//import PatronCartHeader from './PatronCartHeader';  // don't remove this, it is used while processing register definitions

import * as actions from '../../store/actions';

import "./Pos.scss";
import Locations from '../../api/Locations';

const INTERVAL_HOURS_CHECK=900000;

export const POS = (props) => {
    const dispatch = useDispatch();
    const params = useParams();
    const location = useLocation();
    const mountedRef = useRef(false);
    const defaultPatronRegisterId = useSelector(state=>state.pos.register);
    let patronLayout = props.patron_layout || false;

    let id;
    if (location.pathname.includes('event-register') || location.pathname.includes('/cart') || location.pathname.includes('/shop')) id = defaultPatronRegisterId;
    else if(location.pathname.includes('/online')) {
        id = (+location.pathname.split('/')[3]);
        patronLayout = false; //despite this being a patron register, we want to hide the header, side menu, etc so that the checkout for the online ordering is seperate.  
    }
    else if(props.register_id) id = +props.register_id;
    else if(params.id) id = +params.id;
    else if (location.pathname.includes('/pos/')) id = +location.pathname.split('/pos/')[1];
    else id = 1;

    
    // let id = parseInt(props.register_id) || parseInt(params.id) || 1;

    // this is NECESSARY if the POS component is loaded in more than once places on the same page - for instance in the header, and in the page body
    let skipInitialLoad = props.skip_initial_load || false;

    let componentName = props.componentName || false;       // this is used so that we can still load all the POS functionality but only display one component, such as ShoppingCart

    const orderImported = useSelector(state => state.pos?.[id]?.order_imported);
    const registerInfo = useSelector(state => state.pos?.[id]?.registerInfo);
    const customer = useSelector(state => state.pos?.[id]?.user);
    const reduxOrder = useSelector(state => state.pos?.[id]?.orderAll);
    const finishedLoadingAllReady = useSelector(state => state.pos?.[id]?.finished_loading_all);
    const finishedLoadingCustomer = useSelector(state => state.pos?.[id]?.finished_loading_customer);
    const authUser = useSelector(state => state.auth.user.profile);
    const company = useSelector(state => state.company);

    const coupons_selected = useSelector(state => state.pos?.[id]?.coupons_selected);
    const tip = useSelector(state => state.pos?.[id]?.tip);
    const orderItems = useSelector(state => state.pos?.[id]?.items);

    const [content,setContent]= useState();
    const [startedLoadingItems, setStartedLoadingItems] = useState(false);
    const [finishedLoadingItems, setFinishedLoadingItems] = useState(false);
    const [numItemsToLoad, setNumItemsToLoad] = useState();
    const [startedLoadingCustomer, setStartedLoadingCustomer] = useState(false);
    const [registerGroup, setRegisterGroup]=useState(null);
    const [registerOpen, setRegisterOpen]=useState(true)

    let localOrderId = JSON.parse(localStorage.getItem(`POS-${id}-order-id`))

    const [patronRegisterId, setPatronRegisterId] = useState();
    const GUEST_USER_ID = company?.config?.guest_user_id || null;

    const getTaxRate=useCallback(async(locationId)=>{
        try{
            let response = await Locations.get({id: locationId});
            if(response.status === 200 && response.data.length > 0){
                if(response.data[0].tax_rate) dispatch(actions.setTaxRate(response.data[0].tax_rate, id))
                else {
                    await getTaxRate(response.data[0].parent_id)
                } 
            }
        }catch(ex){console.error(ex)}
    },[dispatch, id])

    useEffect(() => {
        if(registerInfo && !GUEST_USER_ID && registerInfo.id === id && !registerInfo.register_definition.is_patron_register){
            console.log("No guest user id set in company settings");
        }
    },[GUEST_USER_ID, registerInfo, id]);

    useEffect(() => {
        mountedRef.current = true;

        const checkHoursAndChanges=setInterval(async()=>{
            if(registerInfo){
                let response = await Pos.register.get({id: id, is_patron_register: registerInfo.register_definition.is_patron_register})
                if(response.data[0].register_definition.hasOwnProperty("change_id") && registerInfo.register_definition.hasOwnProperty("change_id")){ //we have to make sure this field exists before checking it
                    if(response.data[0].register_definition.change_id !== registerInfo.register_definition.change_id){
                        dispatch(actions.setFinishedLoadingAll(false, id));
                        dispatch(actions.setRegisterInfo(response.data[0], id))
                    }else{
                        //if we just checked for changes, then we check the hours and if we need to change categories.
                        let availability={...registerInfo?.register_definition.availability}
                        if(availability){
                            let open = determineRegisterAvailability(availability)
                            if(open !== registerOpen) setRegisterOpen(open)
                        }
                    }
                }
            }
        }, INTERVAL_HOURS_CHECK)

        return () => {
            clearInterval(checkHoursAndChanges)
            mountedRef.current = false;
        }
    },[]);

    useEffect(() => {
        // if register info hasn't loaded into redux yet, trigger it to load
        dispatch(getRegisterInfo(id, props.is_patron_register));
    },[id, dispatch, props.is_patron_register]);

    

    // once the register info is loaded convert the register_definition into components
    useEffect(() => {
        if (registerInfo && !finishedLoadingAllReady) {
            let registerCopy = {...registerInfo};
            if (componentName) {
                // if we are only loading one component, then we don't need to load the register definition
                registerCopy.register_definition = {
                    "components": {
                        [componentName]: {
                            "props": {...props}
                        }
                    }
                };
            }
            // regular POS functionality - load the entire register definition
            setContent(convertRegisterDefinition(registerCopy, {...props}));
        }
        if(registerInfo && registerInfo.register_definition.availability){
            let availability={...registerInfo.register_definition.availability}
            let registerOpen = determineRegisterAvailability(availability)
            setRegisterOpen(registerOpen)
        }

    // having props in here causes the products to redraw every time there's a change, which causes both a flicker and bypasses adding variants and addons with the redraw of the products it causes
    // eslint-disable-next-line react-hooks/exhaustive-deps    
    }, [registerInfo, componentName,finishedLoadingAllReady]);

    useEffect(() => {
        if (props.events) dispatch(actions.showEventRegister(id));
        else dispatch(actions.hideEventRegister(id));
    },[props.events, id, dispatch]);

    useEffect(() => {
        if(patronRegisterId && id !== patronRegisterId) dispatch(actions.selectCustomer(null, id));
    },[id,patronRegisterId,dispatch]);

    useEffect(()=>{
        // suppress Order.update until current order is fully loaded
        dispatch(actions.setFinishedLoadingAll(false, id));
    },[id,dispatch]);

    // this exists to make sure that all of the items from the call have been processed and are loaded into redux BEFORE we update the order
    const checkNumItemsToLoad = useCallback(() => {
        return ((numItemsToLoad || 0)===orderItems.length);
    }, [orderItems, numItemsToLoad]);

    useEffect(()=>{
        if (!finishedLoadingAllReady && finishedLoadingCustomer && finishedLoadingItems && checkNumItemsToLoad()) {
            dispatch(actions.setFinishedLoadingAll(true, id));
        }
    },[finishedLoadingCustomer, finishedLoadingItems, id, checkNumItemsToLoad, finishedLoadingAllReady, dispatch]);

    const clearOldOrder = useCallback(async () => {
        //if you don't remove the order and reset redux before starting this process, leftover pieces (items, customer) would end up in the resumed order
        //It means you'll lose what was in the preview before, but it can easily be the order resumed here
        try{
            dispatch(actions.reset(id));
        } catch(ex){console.info(ex)}
        finally {
            // await Pos.local.remove(`POS-${register_id}-order-id`);
        }
    },[dispatch, id]);

    //clears the customer in local state because if an open order from the guest account is selected, it would apply this customer instead.
    // reset finishedLoading to false so the order doesn't get updated until the customer and items are loaded
    useEffect(() => {
        const _clear = async()=> await clearOldOrder();

        if(orderImported) {
            dispatch(actions.orderImported(false, id));
            dispatch(actions.setFinishedLoadingCustomer(false, id));
            setFinishedLoadingItems(false);
            dispatch(actions.setFinishedLoadingAll(false, id));
            _clear();
            // when redux order is empty and localID exists it should trigger the useEffect below to load the order
        }
    },[orderImported, id, dispatch, clearOldOrder]);


    // load a patron order the first time!
    useEffect(() => {
        if (!skipInitialLoad) {

            // if this is a patron register, use the auth user to load the latest order
            if (id && authUser && registerInfo?.register_definition?.is_patron_register===true && authUser.id !== GUEST_USER_ID && !reduxOrder) {

                if (!finishedLoadingCustomer) {
                    dispatch(actions.selectCustomer(authUser, id));
                    dispatch(actions.setFinishedLoadingCustomer(true, id));
                }

                if (!startedLoadingItems) {
                    setStartedLoadingItems(true);  // prevent calling twice
                    // loads the order from the server

                    Pos.order.latestOpen({ user_id: authUser.id, register_id: id })
                    .then( async response => {
                        if (mountedRef.current && !response.errors && response.data) {
                            if (response.data.order_status_id === 1){
                                dispatch(loadOrderIntoRedux(response.data, id));
                                setNumItemsToLoad(response.data?.items?.length);
                                setFinishedLoadingItems(true);
                            } else {
                                Pos.local.remove(`POS-${defaultPatronRegisterId}-order-id`);
                                dispatch(actions.setPCReset());
                                dispatch(actions.reset());
                            }
                        } else if (response.errors) {
                            console.error(response.errors);
                        }
                    });
                }

                // retrieve a service booking, if it exists
                Pos.local.get("service-booking")
                .then( booking => {
                    if (mountedRef.current && booking) {
                        let newBooking = booking;
                        newBooking.selected_date = new Date();
                        dispatch(actions.setServiceBooking(newBooking));
                    }
                }).catch( e => console.error(e) );
            }
        }
    },[id, registerInfo, authUser, reduxOrder, startedLoadingItems, finishedLoadingCustomer, GUEST_USER_ID, skipInitialLoad, defaultPatronRegisterId, dispatch]);

    // load a POS order the first time!
    useEffect(() => {
        // if this is a pos register, load the order from local storage
        // reads localstorage and updates redux
        if(registerInfo && (!registerInfo.register_definition?.hasOwnProperty('is_patron_register') || !(registerInfo.register_definition?.is_patron_register))) {
            if (mountedRef.current && id){
                getTaxRate(registerInfo.location_id);
                if (!reduxOrder && localOrderId) {
                    // loads the order from the server
                    Pos.order.get({id: localOrderId})
                    .then( response => {
                        if (mountedRef.current && !response.errors && response.data && response.data.split_order_ids.length===0){
                            // load the customer
                            if (response.data?.user_id && +response.data.user_id !== GUEST_USER_ID){
                                Users.get({id:response.data.user_id})
                                .then(response=>{
                                    if (response.data){
                                        setStartedLoadingCustomer(true);
                                        dispatch(actions.selectCustomer(response.data[0], id));
                                    } else { //no customer
                                        dispatch(actions.setFinishedLoadingCustomer(true, id));
                                    }
                                }).catch(e => console.error(e));
                            } 
                            else { // no customer for this order
                                dispatch(actions.setFinishedLoadingCustomer(true, id));
                            }
                            setNumItemsToLoad(response.data.items.length);
                            dispatch(loadOrderIntoRedux(response.data, id));
                            setFinishedLoadingItems(true);
                        } 
                        else { //no order in db
                            dispatch(actions.setFinishedLoadingCustomer(true, id));
                            setFinishedLoadingItems(true);
                        }
                    });
                } else if (!localOrderId) {
                    // no order in local storage
                    dispatch(actions.setFinishedLoadingCustomer(true, id));
                    setFinishedLoadingItems(true);
                } else {
                    // a order exists in local storage and is being loaded right now into redux
                }
            } else { 
                // error somewhere? no id? this should probably throw an error here rather than this
                dispatch(actions.setFinishedLoadingCustomer(true, id));
                setFinishedLoadingItems(true);
            }
        }
    },[id, registerInfo, authUser, reduxOrder, localOrderId, customer, GUEST_USER_ID, dispatch, getTaxRate]);

    
    // Leo: added this because refreshing was not triggering finishedLoadingAllReady which was preventing the order from updating
    useEffect(() => {
        if (reduxOrder && !finishedLoadingItems && !numItemsToLoad) {
            setNumItemsToLoad(reduxOrder?.items?.length);
            setFinishedLoadingItems(true);
        }
    },[reduxOrder, finishedLoadingItems, numItemsToLoad]);

    useEffect(() => {
        // used in POS reloads
        // this is to set the finishedLoadingCustomer flag to true once the customer has actually been loaded into redux
        if (startedLoadingCustomer && !!customer) {
            dispatch(actions.setFinishedLoadingCustomer(true, id));
        }
    },[startedLoadingCustomer, customer, dispatch, id]);

    // if customer, coupons, or tip changes call an update to the order on the server
    useEffect(() => {        
        if (finishedLoadingAllReady) {
            dispatch(saveLogging(`dispatch updateCart Pos 249`));
            dispatch(updateCart(id));
            
        }
    },[customer, coupons_selected, tip, finishedLoadingAllReady, id, dispatch]);


    return (
        <div 
            className={`
                ${patronLayout ? "pos-patron-container" : `pos-container`} 
                ${props.componentName && "transparent"} 
                register-${id} 
                ${!props.events ? "no-events" : ""}`
            }
        >
            {registerOpen ?
                <div className="pos-content">
                    {content}
                </div>
                :
                <ClosedHours
                    subHeaderItems={[
                        {linkAs: Link, linkProps: {to: "/p/home"}, text: "Home"},
                        {text: "Courtside Ordering Closed!"}
                    ]}
                    hours={registerInfo?.register_definition?.availability}
                    registerName="the Courtside Pickup"
                />
            }
        </div>
    );
}


