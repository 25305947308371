import React from 'react';
import { getDay, setHours, setMinutes, setSeconds, format } from 'date-fns'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import {stringToUUID} from '../api/Common';

import Card from 'react-bootstrap/Card';
import Preview from '../containers/POS/Preview';
import Buttons from '../containers/POS/Buttons';
import Items from '../containers/POS/Items';
import Users from '../containers/POS/Users';
import Locations from '../containers/POS/Locations';
import NumberTabs from '../containers/POS/NumberTabs';
import Register from '../containers/POS/Events/Register';
import Banner from '../containers/POS/Banner';
import PatronCartHeader from '../containers/POS/PatronCartHeader';
import PatronCart from '../containers/POS/Payment/PatronCart';
import ShoppingCart from '../components/common/ShoppingCart';
import CheckinUserList from '../components/common/CheckinUserList';
import OnlinePickupBanner from '../containers/Orders/OnlinePickup/OnlinePickupBanner';
import ClosedHoursTrigger from '../containers/POS/ClosedHours/ClosedHoursTrigger';
import { PosHeader } from '../containers/POS/PosHeader';


// converts a json register definition to tag notation
export const convertRegisterDefinition = (register_info, component_props={}) => {
    let is_checkout = component_props.checkout || false;

    const registerDefinitionJson = typeof register_info.register_definition==="string" ? JSON.parse(register_info.register_definition) : register_info.register_definition;
 
    // this allows us to pass in the name, description, url all into the components for display
    let {register_definition, id, ...registerProperties } = register_info;
    registerProperties.register_id = id;

    let {components, url, is_patron_register, ...otherProperties} = registerDefinitionJson;
    registerProperties.url = url || null;
    registerProperties.checkout_url = url ? url + "/checkout" : null;
    registerProperties.is_patron_register = !!is_patron_register ? 1 : 0;    // needed because true/false throws an error when you add it to one of the divs
    // handle any other properties that are passed in, doesn't matter what they are just pass them along in the props
    Object.keys(otherProperties).forEach((key) => {
        if(key==="category_id" && otherProperties.hasOwnProperty("category_availability")) {
            let catId = checkDayCategory(otherProperties)
            registerProperties.category_id = catId;
        }else registerProperties[key] = otherProperties[key] || null;
    })


    const readData = (obj, ele=[]) => {
        if (obj){
            if (Array.isArray(obj)){
                obj.forEach(o=>{

                    ele.push(loopObject(o));
                })
            } else ele=loopObject(obj);        
            return ele;
        }
        return null;
    }

    const loopObject = (obj) => {
        let ele;
        const Components = {
            Card: Card,
            Buttons: Buttons,
            Preview: Preview,
            Items: Items,
            Users: Users,
            Locations: Locations,
            NumberTabs: NumberTabs,
            Events: Register,
            CheckinUserList: CheckinUserList,
            ShoppingCart: ShoppingCart,
            Banner: Banner,
            PatronCartHeader: PatronCartHeader,
            PatronCart: PatronCart,
            PosHeader: PosHeader,
            OnlinePickupBanner: OnlinePickupBanner,
            ClosedHoursTrigger: ClosedHoursTrigger
        };
        Object.entries(obj).forEach(([key, value]) => {
            // if (key.substr(0,1)===key.substr(0,1).toUpperCase()){ // uppercase means we're dealing with a component
                let aprops={key:stringToUUID(Math.random())};
                if (value.props){
                    // if value props is an array convert
                    aprops={...aprops,...value.props};
                }
                // if one of the classnames=cart-checkout AND checkout=true then replace the content of that component with the PatronCart component
                // this allows us to build two version of the register - one for viewing the items, one for the checkout page, all with the same register definitions
                let classNames = aprops.hasOwnProperty('className') ? aprops.className.split(' ') : [];
                
                if (is_checkout && classNames.includes('cart-checkout')) {
                    ele = React.createElement(PatronCart, {...registerProperties, ...aprops, ...component_props});
                } else {
                    if (registerProperties.is_patron_register && aprops.className && aprops.className.includes("cart-checkout") && !aprops.className.includes("content-card")) {
                        //At this point, we have a lot of registers already made without the content card class name to help the cards align, 
                        //so we need to add this classname on the component level when we're designing layouts in the future or continue to add the "cart-checkout" classname and let this finish the job.
                        aprops.className += " content-card"; // add the content-card class to all patron facing registers 
                    } else if (registerProperties.is_patron_register && aprops.className) {
                        aprops.className += " container"; // add the container class to all patron facing registers
                    }
                    // register_id gets added to every element as a prop, doesn't need to be added into the actual register definition
                    ele = React.createElement(Components?.[key] || "div", {...registerProperties, ...aprops}, readData(value?.content));
                }
            // }
        });
        return ele;
    }

    const html = readData(components);

    return html;
}

export const injectCollectJS = (collectJsUrl, tokenizationKey) => {
    if (document.querySelector('#collect-js')) return;
    const script = document.createElement('script');
    script.setAttribute('src', collectJsUrl);
    script.setAttribute('data-tokenization-key', tokenizationKey);
    script.setAttribute('data-variant', 'inline');
    script.setAttribute('id', 'collect-js');
    document.querySelector('body').appendChild(script);
    /*return new Promise((resolve, reject) => {
        script.onload = function() {
            resolve(window.CollectJS);
        }
    });*/
}


export const base64ToFile = async (base64String, filename = 'file.jpg') => {
    var arr = base64String.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]), 
    n = bstr.length, 
    u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});    
    
    /*const parts = base64String.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    const blob = new Blob([uInt8Array], { type: contentType });
    return new File([blob], filename, { type: contentType });*/
}

//#region date/time handling
//used for POS open hours/categories
export const determineRegisterAvailability = (registerAvailability)=>{
    let copy = JSON.parse(JSON.stringify(registerAvailability)) 

    //date FNS the day of the week starts on Sunday - Sunday === 0
    let available = true;
    let today = new Date();
    let todayDay = getDay(today); //will return numeric version of day of week.  
    let todayHours;
    if(copy?.days) todayHours = copy?.days[todayDay]; //the hours for today

    //There's a flag for closed:
    if(todayHours?.closed)available = false;
    else if(!todayHours.start && !todayHours.end) available = true //if there are no times it's considered open all 24 hours
    else { //if it isn't closed, does it fall inside working hours
        let todayStart = convertTimeString(todayHours?.start, registerAvailability.timezone);
        let todayEnd = convertTimeString(todayHours?.end, registerAvailability.timezone);

        if(today < todayStart || today > todayEnd){
            //Checking if it's outside working hours
            available = false;
        } 
    }

    if(available) { //if, after the standard hour/day check we're still open, make sure it isn't a day off date
        available = checkDayOff(copy.extra_closed, today)
    }
    
    return available;
}

export const convertTimeString =(time, timezone)=>{
    if(time){
        let split = time?.split(":");
        let converted = setHours(new Date(), parseInt(split[0]));
        converted = setMinutes(new Date(converted), parseInt(split[1]));
        converted = setSeconds(new Date(converted), 0)
        if(timezone) converted = zonedTimeToUtc(new Date(converted), timezone);
        return converted;
    }else return null;
}

export const checkDayOff=(extraClosed, today)=>{
    let available = true
    for(let i = 0; i < extraClosed?.length; i++){
        //we format them so that the year doesn't matter
        let formattedToday = format(new Date(today), "MM/dd");
        let formattedClosed = format(new Date(extraClosed[i]), "MM/dd")
        let isSame = formattedToday === formattedClosed;
        if(isSame) {
            available = false;
            break
        }
    }
    return available
}

/**if null is returned, something else needs to be used for the category */
export const checkDayCategory=(properties)=>{
    let availableCategory = null;
    if(typeof(properties) === "string") availableCategory = JSON.parse(JSON.stringify(properties))?.category_availability;
    else availableCategory = properties?.category_availability
    let currentDate = format(new Date(), "MM/dd");
    let time = format(new Date(), "HH:mm");
    let day = getDay(new Date());
    let categories = [];
    let hasExtra = null;
    let correctCategoryId = null;

    //check the date itself, first - if there's a specific date, it will override the day of the week
    //check if it's the date
    hasExtra = availableCategory?.extra_dates?.find((day)=>day.date === currentDate)
    if(!hasExtra){
        //category by day of the week
        //Sunday starts at 0 for datefns & our array
        categories = availableCategory?.days[day];
    }else categories = hasExtra;

    //if there's no start time (and there's only one), it's all day
    if(categories?.categories?.length === 1 && !categories?.categories[0].start) correctCategoryId = categories?.categories[0].cat_id;

    let confirmedTime = []
    //check the time
    if(categories?.categories?.length){
        categories?.categories?.forEach((category)=>{
            if(!category.start) confirmedTime.push(category.cat_id);
            else if(category?.start < time && category?.end > time) confirmedTime.push(category.cat_id)
        })
        correctCategoryId = confirmedTime;
    }
    
    //if categories don't exist here, use default
    if(!correctCategoryId && availableCategory?.default?.id) correctCategoryId = [availableCategory?.default?.id];
    else if(!correctCategoryId && !availableCategory?.default?.id) correctCategoryId = null;


    return correctCategoryId;
}
//#endregion date/time handling

//#region date/time error checking

/** will return true if one value is input and missing the other */
export const checkMissingStartOrEnd=(start, end)=>{
    if((start && !end) || (!start && end)) return true
    else return false
}

export const checkDupeDates=(date, closedDates)=>{
    let formatted = format(date, "MM/dd")
    let isIncluded = closedDates.find((day)=>day === formatted);
    return isIncluded;
}

/**will return true if the start and end time are both present but the end time is earlier than the start time.  Will also return true if missing a value*/
export  const checkIsOneGreaterTwo=(timeOne, timeTwo)=>{
    if(timeOne && timeTwo && timeOne > timeTwo) return true;
    else if((timeOne && !timeTwo) || (!timeOne && timeTwo)) return true; //there's a different handler at the end for one day and not the other.  We won't check times if we only have one
    else return false;
}

//#endregion date/time error checking