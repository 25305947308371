/**Returns environment variable that matches those on the archive list 
 * 
 * @param {{string}} env - passed in from env file to dermine environment
*/
export const determineEnvironment=()=>{
    const { REACT_APP_API_URL } = process.env;
    if(REACT_APP_API_URL.includes('dev')) return "dev";
    else if(REACT_APP_API_URL.includes('qa')) return "qa";
    else return "prod";
}

/**returns the product id for a particular stream
 * 
 * @param {{object}} stream - stream object from archive list
 */
export const determineProductId=(stream)=>{
    const productType = determineEnvironment();
    const productId = stream.product_ids[productType];
    return productId;
}

/**filters the given stream list based on a product id
 * 
 * @param {{array}} streamList - list of streams from archive list
 * @param {{int}} productId - product id to filter by
 */
export const filterStreamList=(streamList, productId)=>{
    const productType = determineEnvironment();
    const filteredList = streamList.filter((stream)=>stream.product_ids[productType]===productId);
    return filteredList;
}

/** checks an individual stream day for whether or not it has links */
export const dayRecordings=(streamDay)=>{
        let hasRecording = streamDay.some((stream)=>{
            return stream?.link !== null
        })
        return hasRecording 
}

/**Checks a stream event for whether or not it has any links to view */
export const streamRecordings=(streamEvent)=>{
    let hasRecordings;
    //we should include in the data if they have recordings or not to avoid all the extra logic and looping, but just in case, there are methods for ascertaining if they have them or not.
    if(streamEvent.hasOwnProperty("recordings")) hasRecordings = streamEvent.recordings
    else if(streamEvent?.days){
        for(let i = 0; i < streamEvent.days.length; i++){
            hasRecordings = dayRecordings(streamEvent.days[i].schedule);
            if(hasRecordings) break;
        }
    }
    else if(streamEvent?.blocks){
        for(let i = 0; i < streamEvent.blocks.length; i++){
            hasRecordings = dayRecordings(streamEvent.blocks)
            if(hasRecordings) break;
        }
    }
    streamEvent.status = hasRecordings
    return hasRecordings;
}

/** loops through all of the events to see if they're viewable */
export const checkAllStreamViewability=(streamEventList)=>{
    for(let i = 0; i < streamEventList.length; i++){
        streamRecordings(streamEventList[i]);
    }
}

export const gameHasRecordings=(game)=>{
    if(game){
        if(game?.link  || game?.link2 ) return true;
        else return false;
    }
}